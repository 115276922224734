






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              actionType: {
                attrs: {
                  label: "Hành động",
                },
                rules: {
                  equal_to: {},
                },
              },
              targetType: {
                attrs: {
                  label: "Đối tượng",
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Người thực hiện",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              remoteIp: {
                type: "text",
                attrs: {
                  label: "IP",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            user: {
              text: "Người thực hiện",
              sortable: true,
              options: {
                subProp: "user.name",
              },
            },
            actionType: {
              text: "Hành động",
              sortable: true,
              options: {
                label: true,
              },
            },
            targetType: {
              text: "Đối tượng",
              sortable: true,
              options: {
                label: true,
              },
            },
            remoteIp: {
              text: "IP",
              sortable: true,
            },
            createdTime: {
              text: "Thời gian tác động",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          findAllOptionsFilter(options) {
            return options;
          },
          template: {
            itemActionButtons: {
              download: {
                attrs: {},
                content: {
                  icon: "mdi-download",
                },
                on: {
                  xClick({ self }) {
                    const { item } = self.context();
                    coreApiClient.download("actionLogs", `${item._id}/files/${item.fileItems[0].uuid}`);
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Download",
                    },
                  },
                },
                ext: {
                  condition(_, item) {
                    return item && item.fileItems && item.fileItems.length;
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("actionLogs", "findAll", options);
              return [items, count];
            },
          },
          delete: {},
        },
      },
    };
  },
});
